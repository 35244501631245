import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { CANALBANCARIOEXTERIOR } from "../../util/typesReactQuery";
import {
  GetCanalBancarioExteriorByIDMoeda,
  EnviarEmailRemetenteExterior,
} from "../../api/FormaPagamento";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import useAlert from "../../hooks/useAlert";
import { Box, Card, CardContent, Divider, Skeleton } from "@mui/material";
import Subtitulo from "../Textos/Subtitulo";
import parseHtml from "html-react-parser";
import MultSkeleton from "../MultSkeleton";
import ButtonAcaoGrande from "../Botoes/ButtonAcaoGrande";
import Modal from "../Modal/Modal";
import CampoEmail from "../Campos/CampoEmail";
import useStyle from "./style";
import Titulo from "../Textos/Titulo";
import DispatchEmail from '../DispatchEmail';


const CanalBancarioExterior = ({
  itemVitrine,
  isLoading,
  setIsLoading,
  sx,
}) => {
  const classes = useStyle();
  const { Alert, showAlertError, showAlertSuccess, showAlertInfo } = useAlert();

  const [isRecebimento, setIsRecebimento] = useState(false);
  const [formaPagamento, setFormaPagamento] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const { refetch: refetchCanalBancario } = useQuery(
    CANALBANCARIOEXTERIOR,
    () => GetCanalBancarioExteriorByIDMoeda(itemVitrine?.moeda?.id),
    {
      onSuccess: (data) => {
        if (!data || !!data.errorMessage) {
          console.error(`${data.errorMessage} - R3A1E`);
          showAlertError(
            `Ops, algo deu errado, por favor informe o suporte o seguinte error: Não foi possivel encontrar nenhum canal exterior - R3A1E `
          );
        }
        setFormaPagamento(data.data);
      },
      onError: (error) => {
        console.error(` ${error} - R20EF`);
        showAlertError(
          `Ops, algo deu errado, por favor informe o suporte o seguinte error: ${error} - R20EF `
        );
      },

      enabled: false,
    }
  );

  useEffect(() => {
    if (itemVitrine?.moeda?.id > 0) {
      refetchCanalBancario();
    }
  }, [itemVitrine?.moeda?.id]);

  const handlerManipulaModal = (event) => {
    setShowModal(!showModal);
  };



  return (
    <div>
      <Alert />
      <Modal
        show={showModal}
        close={handlerManipulaModal}
        hasButtonClose={true}
      >
        <Box sx={{ mt: "1.5rem" }}>
          <Titulo sx={{ fontSize: 20 }}>Envie por e-mail</Titulo>

          <Subtitulo sx={{ mt: "1rem", mb: "1rem" }}>
            Envie as informações de pagamento, para que o remetente possa
            realizar o pagamento da moeda estrangeira.
          </Subtitulo>

          <DispatchEmail showEmailError={(i) => showAlertError(i)} showEmailSuccess={(i) => showAlertSuccess(i)} showModal={showModal} setShowModal={setShowModal} moeda={itemVitrine?.moeda} />

          {/* <CampoEmail
            size="small"
            label="Email"
            value={email}
            handlerChange={handlerChangeEmail}
          />

          <ButtonAcaoGrande Handle={handlerSendEmail} color="primary" fullWidth>
            Enviar e-mail
          </ButtonAcaoGrande> */}
        </Box>
      </Modal>
      <Titulo sx={{ fontSize: 16, fontWeight: "bold", display: "flex", justifyContent: "space-between", alignItems: 'center', color: 'black' }}>
        Dados de pagamento para: {itemVitrine?.moeda?.simbolo}
        {formaPagamento && (
          <>
            <ButtonAcaoGrande
              variant="contained"
              Handle={handlerManipulaModal}
              className={classes.customBotao}
            >
              <MailOutlineIcon sx={{ fontSize: 18, mr: 1 }}></MailOutlineIcon>
              Enviar por email
            </ButtonAcaoGrande>
          </>
        )}
      </Titulo>

      <Divider sx={{ mb: 1, mt: 1 }}></Divider>
      <Card
        className={classes.Card}

      >
        <CardContent className={classes.CardContent}>
          {isLoading ? (
            <Skeleton animation="wave" height={50} width={"100%"} />
          ) : (
            <Box className={classes.titleCard}></Box>
          )}

          {isLoading ? (
            <>
              <MultSkeleton quantidade={5} animation="wave" height={30} />
            </>
          ) : (
            <>
              {formaPagamento ? (

                <>
                  {!!formaPagamento?.descricaoHtml ? (
                    <>
                      <Subtitulo
                        sx={{ fontWeight: "bold", mb: 2, color: "#000" }}
                      >
                        {formaPagamento?.descricao}
                      </Subtitulo>
                      <Subtitulo sx={{ color: "#000" }}>
                        {parseHtml(formaPagamento?.descricaoHtml ?? "")}
                        testeefsssgsggssg testeefsssgsggssg testeefsssgsggssg testeefsssgsggssg
                      </Subtitulo>
                    </>
                  ) : (
                    <>
                      {/* <Subtitulo sx={{ fontWeight: 'bold', mb: 2 }}>{f.descricao}</Subtitulo> */}
                      <Subtitulo style={{ color: "#fff !important" }}>
                        {" "}
                        {parseHtml(formaPagamento?.descricao ?? "")}
                      </Subtitulo>
                    </>
                  )}
                </>

              ) : (
                <>
                  <Subtitulo style={{ color: "#fff !important" }}>
                    <br></br>
                    Não foi encontrado nenhum dado de pagamento no exterior para
                    a moeda {itemVitrine?.moeda?.descricao}, por favor, entre em
                    contato conosco para verificarmos a disponibilidade.
                  </Subtitulo>
                </>
              )
              }
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default CanalBancarioExterior;
