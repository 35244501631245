import { useState, useEffect } from "react";
import { Termo } from "../..";
import { styled } from "@mui/material/styles";
import useAlert from "../../../hooks/useAlert/index";
import ButtonAcaoGrande from "../../Botoes/ButtonAcaoGrande";
import Seletor from "../../Seletor";
import Paper from "@mui/material/Paper";
import { Grid, Box, Typography, Hidden } from "@mui/material";
import { DateRangePicker } from "rsuite";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  MenuDashboard,
  PaginasMenuDashboard,
  SubMenusDashBoard,
  BreadCrumbsPaginas,
} from "../..";

import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import PassageiroList from "../../PassageiroList";

import Itens from "./Itens";

import { getVitrine, addItemVitrine } from "../../../api/Carrinho/SeguroViagem";
import { useAuth } from "../../../hooks/AuthContext";

import { Style } from "./styles";
import { Gr } from "react-flags-select";

const Item = styled(Paper)(({ theme }) => ({
  //backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  boxShadow: "none",
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

function VitrineSeguroViagem() {
  const { user } = useAuth();
  const classes = Style();
  const { Alert, showAlertInfo, showAlertSuccess, showAlertError } = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const [isShowItens, setIsShowItens] = useState(false);
  const styles = { width: "100%", display: "block", marginBottom: 10 };

  const [destinos, setDestinos] = useState([]);
  const icone = (
    <Typography
      sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      Embarque
      <ArrowForwardIcon fontSize="small" />
      Desembarque
    </Typography>
  );
  const Embarque = "Embarque";
  const Desembarque = "Desembarque";

  const [form, setForm] = useState({
    idCliente: user.id,
    idHotsite: user.idHotsite ?? 0,
    passageiros: [],
  });

  const init = async () => {
    const response = await getVitrine();
    setDestinos(response.data.destinos);
  };

  useEffect(() => {
    init();
  }, []);

  const breadCrumbs = [
    {
      label: "Seguro de viagem",
      icon: <CompareArrowsIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
    },
    {
      label: "Compra de seguro de viagem",
      icon: <DoubleArrowIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
    },
  ];

  const handlerChangeDestino = (id) => {
    setForm({ ...form, idDestino: id });
  };

  const handlerChangeData = (data) => {
    const de = new Date(data[0]);
    const ate = new Date(data[1]);

    let formNew = form;
    formNew.dataDe = `${de.getFullYear()}-${de.getMonth() + 1}-${de.getDate()}`;
    formNew.dataAte = `${ate.getFullYear()}-${
      ate.getMonth() + 1
    }-${ate.getDate()}`;
    setForm(formNew);
  };

  const handlerChangePassageiro = (p) => {
    setForm({ ...form, passageiros: p });
  };

  const handlerSearch = async () => {
    try {
      const response = await addItemVitrine(form);

      if (response.status !== 200) throw new Error(response.data);

      setIsShowItens(true);
    } catch (error) {
      showAlertError(error.message);
    }
  };

  return (
    <div>
      <Alert />
      <MenuDashboard
        pagina={PaginasMenuDashboard.Remessas}
        subMenuSelecionado={SubMenusDashBoard.Envio}
      >
        <Hidden smUp>
          <Box sx={{ pl: 2 }}>
            <BreadCrumbsPaginas list={breadCrumbs} />
          </Box>
        </Hidden>

        <Hidden smDown>
          <Box sx={{ pt: 2 }}>
            <BreadCrumbsPaginas list={breadCrumbs} />
          </Box>
        </Hidden>
        <Grid
          container
          item
          xs={12}
          md={12}
          lg={12}
          style={{ display: "block" }}
        >  <Grid
        
        item
        xs={12}
        md={12}
        lg={8}
        style={{
          margin:'auto',
          justifyContent: "center",
          background: "#fff",
          padding: 20,
          borderRadius: 15,
         
        }}
      >
          <Typography
            sx={{ mb: 1, fontWeight: "bold", mt: 0 }}
            variant="h6"
            color="text"
          >
            Seguro Viagem
          </Typography>
          <Typography color="text.secondary" sx={{ mb: 2 }}>
            Faça a cotação do seu Seguro Viagem
          </Typography>
</Grid>
          <Grid
            container
            item
            xs={12}
            md={12}
            lg={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Grid
              container
              item
              xs={12}
              md={12}
              lg={8}
              style={{
                display: "flex",
                justifyContent: "center",
                background: "#fff",
                padding: 20,
                borderRadius: 15,
                boxShadow: "0px 0px 5px 5px #eee",
              }}
            >
              <Grid   className={classes.Seletor} sx={{ mt: 1, mb: 2 }} lg={5} xs={12}>
                {" "}
                <Typography sx={{ mb: 1, fontWeight: "bold" }}>
                  Qual seu destino?
                </Typography>
                {destinos && (
                  <Seletor
                    setSelect={handlerChangeDestino}
                    list={destinos}
                    size="medium"
                    // label="Escolha o país"
                    variant="outlined"
                    className={classes.Seletor}
                    fullWidth={true}
                    value={form.idDestino}
                  />
                )}
              </Grid>
              <Grid sx={{ mt: 1 }} lg={5} xs={12}>
                <Typography sx={{ mb: 1, fontWeight: "bold" }}>
                  Data de viagem
                </Typography>
                <DateRangePicker
                  showWeekNumbers
                  size="lg"
                  placement="bottomEnd"
                  showOneCalendar
                  preventOverflow
                  ranges={[]}
                  format="dd-MM-yyyy"
                  placeholder={icone}
                  onChange={handlerChangeData}
                  style={styles}
                />
              </Grid>
              <Grid sx={{ mt: 1, width: "90%" }} xs={12} lg={10}>
                <PassageiroList onChange={handlerChangePassageiro} />
              </Grid>{" "}
              <Grid
                sx={{ mt: 0, display: "flex", justifyContent: "end" }}
                xs={12}
                lg={8}
              ></Grid>
              <Grid
                sx={{ mt: 0, display: "flex", justifyContent: "end" }}
                className={classes.buttonSearch}
                xs={12}
                lg={10}
              >
                <Grid
                  sx={{ display: "flex", mr: 0, justifyContent: "end" }}
                  lg={7}
                >
                  <ButtonAcaoGrande
                    Handle={handlerSearch}
                    texto="Cotar agora"
                    className={classes.buttonSearch}
                  />
                </Grid>
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  
                  mt:2,
                  justifyContent: "end",
                }}
                lg={9}
              >
                <Typography
                  variant="subtitle1"
                  color="primary"
                  sx={{ textAlign: "end" }}
                  textAlign="end"
                >
                  Ao continuar você aceita nossos Termos e Condições
                </Typography>
              </Grid>
            </Grid>{" "}
          </Grid>

          {/* <div className={classes.ContainerSearch}>
            <table className={classes.Table}>
              <tr>
                <td className={classes.TableItem}>
                  {destinos && (
                    <Seletor
                      setSelect={handlerChangeDestino}
                      list={destinos}
                      size="small"
                      label="Vai para onde?"
                      variant="outlined"
                      sx={{width:'100%'}}
                      fullWidth={true}
                      value={form.idDestino}
                    />
                  )}
                </td>
                <td className={classes.TableItem}>
                  <DateRangePicker
                    showWeekNumbers
                    onChange={handlerChangeData}
                    style={{ width: "100%" }}
                  />
                </td>
                <td className={classes.TableItem}>
                  <PassageiroList onChange={handlerChangePassageiro} />
                </td>
                <td className={classes.TableItem}>
                  <ButtonAcaoGrande
                    Handle={handlerSearch}
                    texto="Cotar agora"
                    className={classes.buttonSearch}
                  />
                </td>
              </tr>
            </table>
          </div> */}
        </Grid>
        <br />
        {isShowItens && <Itens />}
      </MenuDashboard>
    </div>
  );
}

export default VitrineSeguroViagem;
