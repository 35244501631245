import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CampoTexto from "../Campos/CampoTexto";
import CampoDocumentoIdentificacao from "../Campos/CampoDocumentoIdentificacao";
import CampoData from "../Campos/CampoData";
import Seletor from "../Seletor";

import { Style } from "./styles";

const sexos = [
  {
    id: 1,
    descricao: "Masculino",
  },
  {
    id: 2,
    descricao: "Feminino",
  },
  {
    id: 3,
    descricao: "Não Informado",
  },
];

export default function PassageiroItem({ p, onChange }) {
  const classes = Style();

  const handlerChangeSelect = () => {};

  const formatDateValueInput = (value) => {
    if (value.includes("T")) {
      return value.split("T")[0];
    } else {
      return value;
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={1} className={classes.Container}>
        <Grid item xs={4}>
          <CampoTexto
            id="outlined-required"
            sx={{ mt: 0, m: 2 }}
            variant="filled"
            size="small"
            fullWidth={false}
            name="nome"
            label="Nome"
            value=""
            handlerChange={() => {}}
            // isLoading={isLoading}
            //enable={p.enable}
          />
        </Grid>
        <Grid item xs={4}>
          <CampoTexto
            id="outlined-required"
            sx={{ mt: 0, m: 2 }}
            variant="filled"
            size="small"
            fullWidth={false}
            name="sobrenome"
            label="Sobrenome (s)"
            value=""
            handlerChange={() => {}}
            // isLoading={isLoading}
            //enable={p.enable}
          />
        </Grid>
        <Grid item xs={4}>
          <CampoDocumentoIdentificacao
            name="numeroDocumentoIdentificacao"
            value=""
            handlerChange={() => {}}
            idTipoDocumento={1}
          />
        </Grid>
        <Grid item xs={4}>
          <CampoData
            sx={{ mt: 0, m: 1, width: "100%" }}
            size="small"
            label="Data "
            fullWidth={true}
            name="dataNascimento"
            value={formatDateValueInput("2023-01-29")}
            //value={formatDateValueInput(new Date())}
            //onChange={handlerChangeValue}
            onChange={() => {}}
            //className={classes.campo}
            //isLoading={isLoading}
            required={true}
          />
        </Grid>
        <Grid item xs={4}>
          <Seletor
            sx={{ mt: 0, m: 2, pr: 5 }}
            setSelect={(e) => handlerChangeSelect("idSexo", e)}
            list={sexos}
            size="small"
            label="Sexo"
            fullWidth={true}
            value={1}
            //isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
