import { useState, useEffect } from "react";
import CampoTexto from "../Campos/CampoTexto";
import { Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import PersonIcon from "@mui/icons-material/Person";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

//import useStyle from "./styles";
import useStyle from "./styles";

const initPassageiro = {
  idade: 0,
};

function PassageiroList({ onChange }) {
  const cssStyle = useStyle();
  const tarefas = ["1", "2", "3", "4", "5", "6", "7"];
  const [quantidadePassageiro, setQuantidadePassageiro] = useState(0);
  const [passageiros, setPassageiros] = useState([]);
  const [showSeletor, setShowSeletor] = useState(false);

  const add = () => {
    const p = passageiros;
    if (p.length < 7) {
      p.push(initPassageiro);
    }

    setPassageiros(p);
    setQuantidadePassageiro(p.length);
  };

  const remove = () => {


    const p = passageiros;

    if (p.length > 0) {
      p.pop();
    }



    setPassageiros(p);
    setQuantidadePassageiro(p.length);
  };

  const handlerChangePassageiro = (i, v) => {



    const passageirosNew = passageiros.map((p, index) => {
      if (i === index) {
        return {
          idade: v.target.value,
        };
      }

      return p;
    });

    setPassageiros(passageirosNew);
    onChange(passageirosNew);
  };



  return (
    <>
      {/* <div
        className={cssStyle.containerPassageiro}
        onClick={() => setShowSeletor(!showSeletor)}
      >
        Selecione
      </div> */}

      {/* {showSeletor && ( */}
      <div className={cssStyle.containerSelecao}>
        <div className={cssStyle.boxSelecao}>
          <div className={cssStyle.boxInformativo}>

            <Typography sx={{ mb: 1, fontWeight: 'bold' }}>Quantidade de Passageiros</Typography>
            <Typography variant="subtitle1" sx={{ alignItems: "center", display: "flex" }}><PersonIcon fontSize="small" />{quantidadePassageiro} passageiro(s)</Typography>

          </div>
          <div className={cssStyle.boxButton}>
            <RemoveCircleOutlineIcon
              onClick={remove}
              sx={{ fontSize: 25 }}
              className={cssStyle.button}
            />
            <Typography style={{ fontSize: 20 }} className={cssStyle.literalQuantidade}>
              {quantidadePassageiro}
            </Typography>
            <AddCircleOutlineIcon sx={{ fontSize: 25 }} onClick={add} className={cssStyle.button} />

          </div>
        </div>




        <Grid
          container
          xs={12}
          sx={{ flexDirection: "column", alignItems: "center"}}
        >

          <Grid xs={12} className={cssStyle.Item} item >



            {passageiros &&
              passageiros.map((p, i) => {
                return (
                  <>
          <Grid xs={6} lg={2} className={cssStyle.Campo}  item >
                    <CampoTexto
                      value={`${p.idade}`}
                      label="Idade"
                      size="small"
                      name={`passageiro-${i}`}
                      enable={true}
                      handlerChange={(value) =>
                        handlerChangePassageiro(i, value)
                      }
                      key={i}
                      className={cssStyle.campoIdade}
                    /></Grid>
                  </>

                );
              })}
          </Grid>
        </Grid>


      </div>
      {/* )} */}
    </>
  );
}

export default PassageiroList;
