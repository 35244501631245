import api from "../../index";
import TiposOperacao from "../../../util/typesTipoOperacao";
import { configurationVariables } from "../../../environment/index";

const idOrigem = 3; //LojaCambio;
const idHotsite = parseInt(configurationVariables.IdHotsite);

export const addItemEnvio = async (idCliente, itemCarrinho, finalidade) => {
  try {
    const { data: response, status } = await api.post(
      `/CarrinhoRemessa/ConfiguraItemRemessa?idCliente=${idCliente}&idOrigem=${idOrigem}&idHotsite=${idHotsite}`,
      {
        itemEnvio: itemCarrinho,
        natureza: finalidade,
      }
    );

    return {
      status,
      errorMessage: response.errorMessage,
      data: response.data,
    };
  } catch (error) {
    throw new Error(error + "5448");
  }
};

export const getItemEnvio = async (idCliente) => {
  try {
    const { data: response, status } = await api.get(
      `/CarrinhoRemessa/GetItemEnvio?idCliente=${idCliente}&idOrigem=${idOrigem}`
    );

    return {
      status,
      errorMessage: response.errorMessage,
      data: response.data,
    };
  } catch (error) {
    throw new Error(error + "6DE9");
  }
};

export const addItemRecebimento = async (
  idCliente,
  itemCarrinho,
  ordemPagamento,
  finalidade
) => {
  try {
    const { data: response, status } = await api.post(
      `/CarrinhoRemessa/ConfiguraItemRecebimento?idCliente=${idCliente}&idOrigem=${idOrigem}&idHotsite=${idHotsite}`,
      {
        itemRecebimento: itemCarrinho,
        ordemPagamento,
        natureza: finalidade,
      }
    );

    return {
      status,
      errorMessage: response.errorMessage,
      data: response.data,
    };
  } catch (error) {
    throw new Error(error + "2E2B");
  }
};

export const getItemRecebimento = async (idCliente) => {
  try {
    const { data: response, status } = await api.get(
      `/CarrinhoRemessa/GetItemRecebimento?idCliente=${idCliente}&idOrigem=${idOrigem}`
    );

    return {
      status,
      errorMessage: response.errorMessage,
      data: response.data,
    };
  } catch (error) {
    throw new Error(error + "2EE4");
  }
};

export const getPagadorRecebedor = async (idCliente) => {
  try {
    const { data: response, status } = await api.get(
      `/CarrinhoRemessa/GetPagadorRecebedor?idCliente=${idCliente}&idOrigem=${idOrigem}`
    );

    return {
      status,
      errorMessage: response.errorMessage,
      data: response.data,
    };
  } catch (error) {
    throw new Error(error + "0DE5");
  }
};

export const configureItemCarrinho = async (idCliente, pais, cupom) => {
  try {
    const { data: response, status } = await api.post(
      "/CarrinhoRemessa/ConfiguraItemCarrinho",
      {
        idCliente,
        idOrigem,
        pais,
        cupom,
      }
    );

    return {
      status,
      errorMessage: response.errorMessage,
      data: response.data,
    };
  } catch (error) {
    throw new Error(error + "5448");
  }
};

export const addItemPagadorRecebedor = async (idCliente, pagadorRecebedor) => {
  try {
    const { data: response, status } = await api.post(
      `/CarrinhoRemessa/ConfigurarPagadorRecebedor?idCliente=${idCliente}&idOrigem=${idOrigem}`,
      pagadorRecebedor
    );

    return {
      status,
      errorMessage: response.errorMessage,
      data: response.data,
      nextPage: response.nextPage,
      previewPage: response.previewPage,
    };
  } catch (error) {
    throw new Error(error + "45E4");
  }
};

export const addPagamento = async (idCliente, formaPagamento) => {
  try {
    const { data: response, status } = await api.post(
      `/CarrinhoRemessa/ConfigurarFormaPagamento?idCliente=${idCliente}&idOrigem=${idOrigem}`,
      formaPagamento
    );

    return {
      status,
      errorMessage: response.errorMessage,
      data: response.data,
      nextPage: response.nextPage,
      previewPage: response.previewPage,
    };
  } catch (error) {
    throw new Error(error + "57C7");
  }
};

export const finalizar = async (idCliente) => {
  try {
    const { data: response, status } = await api.post(
      `/CarrinhoRemessa/Finalizar?idCliente=${idCliente}&idOrigem=${idOrigem}`
    );

    return {
      status,
      errorMessage: response.errorMessage,
      data: response.data,
    };
  } catch (error) {
    throw new Error(error + "3388");
  }
};
