import { makeStyles } from "@mui/styles";

export const Style = makeStyles((theme) => ({
  ContainerSearch:{
    display: "flex",
    flexDirection: "row",
    margin:"10px 20px",
    width: "100%"
  },
  FormControl: {
    width: "100%",
    border: "none !important",
  },
  Seletor: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
     marginRight:10,
    },
 
  },
  Table:{
  
  },
  TableItem:{
   
  },
  buttonSearch:{
   
   
  }
}));
