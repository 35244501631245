import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { GETALLITENSCARRINHO } from '../../util/typesReactQuery'
import { getAllItensCarrinho } from '../../api/Carrinho/ItensAllCarrinho';
import { useAuth } from '../AuthContext'
import useTimerCarrinho from '../useTimerCarrinho'
import TiposItemCarrinho from '../../util/typesTipoItemCarrinho'
import { getlocalStorageTimerCarrinho, saveLocalStorageTimerCarrinho } from '../../util/LocalStorage';

const usePedido = () => {
    const [pedidos, setPedido] = useState(null)
    const [errorsPedido, setErrorsPedido] = useState(null)
    const [quantidadePedido, setQuantidadePedido] = useState(0)
    const [urlCaminho, setUrlCaminho] = useState("")
    const [alertInfoPedido, setAlertInfoPedido] = useState("")
    const { user } = useAuth();
    const { infoText, errors } = useTimerCarrinho(null, true, true, false)
    const [caminhoVitrine, setCaminhoVitrine] = useState("")


    const VerificaConstaPedido = (obj) => {

        if (!obj && obj.itens.length <= 0)
            return;

        setQuantidadePedido(obj.itens.length);
        setPedido(obj);

        let timerLocalStorage = getlocalStorageTimerCarrinho();

        if (obj?.itens.length > 0) {

            if (!timerLocalStorage && obj?.dataExpiracao)
                saveLocalStorageTimerCarrinho(obj.dataExpiracao);

            //define a vitrine com base no ultimo registro no array 
            switch (obj?.itens.at(-1)?.idTipoItemCarrinho) {

                case TiposItemCarrinho.RemessaExterior:
                    setUrlCaminho("/carrinho/transferencia-internacional/envio/beneficiario")
                    break;
                case TiposItemCarrinho.RecebimentoExterior:
                    setUrlCaminho("/carrinho/transferencia-internacional/recebimento/remetente")
                    break;
                case TiposItemCarrinho.CompraEspecie:
                    setUrlCaminho("/carrinho/turismo/entrega")
                    setCaminhoVitrine("/carrinho/turismo-venda/especie/vitrine")
                    break;
                case TiposItemCarrinho.VendaCartao:
                    setUrlCaminho("/carrinho/turismo/entrega")
                    setCaminhoVitrine("/carrinho/turismo-compra/cartao/vitrine")
                    break;
                case TiposItemCarrinho.RecargaCartao:
                    setUrlCaminho("/carrinho/turismo/entrega")
                    setCaminhoVitrine("/carrinho/turismo-compra/cartao-recarga/vitrine")
                    break;
                case TiposItemCarrinho.CompraSaldoCartao:
                    setUrlCaminho("/carrinho/turismo/entrega")
                    setCaminhoVitrine("/carrinho/turismo-venda/cartao-saldo/vitrine")
                    break;
                case TiposItemCarrinho.VendaEspecie:
                    setUrlCaminho("/carrinho/turismo/entrega")
                    setCaminhoVitrine("/carrinho/turismo-compra/especie/vitrine")
                    break;

                case TiposItemCarrinho.VendaOuro:
                    setUrlCaminho(`/carrinho/turismo/entrega/${true}`)
                    setCaminhoVitrine("/carrinho/Compra-ouro/vitrine")
                    break;

                default:
                    break;
            }
        }

    }


    const { refetch: refetchPedido } = useQuery([GETALLITENSCARRINHO], () => getAllItensCarrinho(user.id), {
        onSuccess: (data) => {
            if (!data) {
                setErrorsPedido(`Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: Não foi encontrado nenhum carrinho - R683C`)
                return;
            }
            if (data.errorMessage) {
                setErrorsPedido(`Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${data.errorMessage} - RB04F`)
                return;
            }

            VerificaConstaPedido(data.data);



        },
        onError: (error) => {
            setErrorsPedido(`Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${error} - RE07D`)
        },
        refetchOnWindowFocus: true,
    })

    useEffect(() => {

        if (!!infoText) {
            setQuantidadePedido(0);
            setUrlCaminho(null)
            setAlertInfoPedido(infoText)
            setPedido(null)
            return;
        }

        if (!!errors) {
            setQuantidadePedido(0);
            setUrlCaminho(null)
            setAlertInfoPedido(infoText)
            setErrorsPedido(errors)
            setPedido(null)
        }

    }, [infoText, errors])


    return { itensPedido: pedidos, errorsPedido, quantidadePedido, alertInfoPedido, caminhoVitrine, urlOperacao: urlCaminho, refetchPedido: refetchPedido }
}

export default usePedido;