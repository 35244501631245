import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { ORDEMPAGAMENTO } from '../../util/typesReactQuery'
import { getByIdCliente } from '../../api/OrdemPagamento';
import { useAuth } from '../AuthContext'

const useOrdemPagamento = () => {
    const [errorsOrdem, setErrorsOrdem] = useState(null)
    const [quantidadeOrdem, setQuantidadeOrdem] = useState(0)
    const [infoOrdem, setInfoOrdem] = useState("")
    const { user } = useAuth();


    useQuery([ORDEMPAGAMENTO], () => getByIdCliente(user.id), {
        onSuccess: (data) => {
            if (!data) {
                setErrorsOrdem(`Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: Não foi encontrado nenhum carrinho - RW6SO`)
                return;
            }
            if (data.errorMessage) {
                setErrorsOrdem(`Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${data.errorMessage} - RG84D`)
                return;
            }

            setQuantidadeOrdem(data.data?.length);

        },
        onError: (error) => {
            setErrorsOrdem(`Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${error} - RM2TW`)
        },
        refetchOnWindowFocus: true,
    })

    return { quantidadeOrdem: quantidadeOrdem, errosMessage: errorsOrdem, infoMessage: infoOrdem }
}

export default useOrdemPagamento;