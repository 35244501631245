import { useState, useEffect, memo } from "react";
import { VitrineBase } from "./VitrineBase";
import Typography from "@mui/material/Typography";
import {
  getVitrineTurismo,
  getValoresItemCarrinhoTurismo,
} from "../../../api/Vitrine";
import { addItem, getCarrinho } from "../../../api/Carrinho/Turismo";
import { useAuth } from "../../../hooks/AuthContext";
import useAlert from "../../../hooks/useAlert/index";
import { LoadingVitrine } from "../../";
import { useNavigate } from "react-router-dom";
import { formatMoney, configInitialvalue } from "../../../util/helpers";
import TiposPedido from "../../../util/typesTipoPedido";
import TiposItemCarrinho from "../../../util/typesTipoItemCarrinho";
import { useQuery } from "react-query";
import usePedido from "../../../hooks/usePedido";
import { formatMoneyStringInDecimal } from "../../../util/helpers";
import { Alert, Card, CardContent, CardHeader, Grid } from "@mui/material";
import {
  GETVALORESITEMSTURISMO,
  VITRINETURISMO,
} from "../../../util/typesReactQuery";
import { useTheme } from "@mui/styles";
import useLojaFechada from "../../../hooks/useLojaFechada";

export default function Turismo({
  idTipoOperacao,
  isEspecie,
  isRecarga = false,
  isCompraNovoCartao = false,
}) {
  const {
    Alert: AlertCustom,
    showAlertSuccess,
    showAlertError,
    showAlertInfo,
    showAlertWarning,
  } = useAlert();

  const theme = useTheme();

  const { user } = useAuth();
  const navigation = useNavigate();
  const [termoAceito, setTermoAceito] = useState(false);
  const [termoRespon, setTermoRespon] = useState(false);
  const [vitrine, setVitrine] = useState(null);
  const [itemVitrine, setItemVitrine] = useState({ valorMe: "" });
  const [loadingButton, setLoadingButton] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [isErrorTermo, setIsErrorTermo] = useState(false);
  const [isErrorTermoResp, setIsErrorTermoResp] = useState(false);
  const [valorItemCarrinho, setValorItemCarrinho] = useState();
  const { refetchPedido: refetchTurismo } = usePedido();
  const { lojaFechada, errors: errorsLojaFechada } = useLojaFechada();

  useEffect(() => {
    if (errorsLojaFechada) {
      showAlertError(errorsLojaFechada);
    }
  }, errorsLojaFechada);

  const [itemCarrinho, setItemCarrinho] = useState({
    idItemVitrine: 0,
    valorMe: 0,
    valorMn: 0,
    impostos: [],
    valorVet: 0,
    idFinalidade: 0,
    idVitrine: 0,
    idHotsite: user?.idHotsite ?? 0,
    valorTaxa: 0,
    idTaxa: 0,
    idPraca: 0,
    idTipoCartao: 0,
    moeda: {},
  });

  const init = async (response) => {
    setLoadingButton(true);
    try {
      // refetchItem();
      setVitrine(response);
      setItemVitrine({
        ...response.itensVitrine[0],
        valorMe: configInitialvalue(response.itensVitrine[0]),
      });
    } catch (error) {
      showAlertError(error.message);
    }

    setLoadingButton(false);
  };

  const { isFetching: isLoadingVitrine } = useQuery(
    [],
    () => getVitrineTurismo(user.id, idTipoOperacao, isEspecie),
    {
      onSuccess: (data) => {
        setVitrine(null);
        if (!data) {
          showAlertError(
            `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: Não foi encontrado nenhuma vitrine de especie - RC4FB`
          );
          return;
        }
        if (!!data?.errorMessage) {
          showAlertError(
            `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${data?.errorMessage} - RGE5A`
          );
          return;
        }

        init(data.data);
      },
      onError: (error) => {
        setVitrine(null);
        showAlertError(
          `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${error} - REZM4`
        );
      },
      onSettled: () => {
        setLoadingButton(false);
      },
      refetchOnWindowFocus: false,
    }
  );

  const { refetch: refetchImpostos, isFetching: isLoadingImpostos } = useQuery(
    [GETVALORESITEMSTURISMO],
    () =>
      getValoresItemCarrinhoTurismo(
        vitrine?.id ?? 0,
        itemVitrine?.id ?? 0,
        vitrine?.idPraca ?? 0
      ),
    {
      onSuccess: (data) => {
        setValorItemCarrinho(null);
        if (!!data?.errorMessage) {
          console.error(
            `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${data?.errorMessage} - RSPHG`
          );
          return;
        }
        setValorItemCarrinho((prev) => ({ ...data?.data }));
      },
      onError: (error) => {
        setValorItemCarrinho(null);
        console.error(
          `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${error} - R3RPP`
        );
      },
      onSettled: () => {
        setLoadingButton(false);
      },
      refetchOnWindowFocus: true,
      refetchInterval: 1000 * 60,
    }
  );

  const isValidacoesPreCalculo = (valor) => {
    if (formatMoneyStringInDecimal(valor) == 0) {
      setDisabledButton(true);
      if (valor != "")
        showAlertInfo(`Só é possível comprar itens com valor acima de 0`);
      return false;
    }

    if (String(valor)?.slice(-2).includes(",")) {
      setDisabledButton(true);
      return false;
    }

    if (
      valorItemCarrinho?.escala > 0 &&
      formatMoneyStringInDecimal(valor ?? 0) % valorItemCarrinho?.escala > 0
    ) {
      setDisabledButton(true);
      showAlertInfo(
        `Só é possível comprar itens em escala de ${valorItemCarrinho?.escala}`
      );
      return true;
    }

    if (
      formatMoneyStringInDecimal(valor ?? 0) < valorItemCarrinho?.valorMinimo
    ) {
      setDisabledButton(true);
      if (!!valor) {
        showAlertInfo(
          `Não é possível solicitar a quandidade de ${valor}, poís o mínimo permitido é ${valorItemCarrinho?.valorMinimo}`
        );
      }
      return true;
    }
    setDisabledButton(false);
    return true;
  };

  useEffect(() => {
    if (itemVitrine) {
      let item = {
        idItemVitrine: itemVitrine?.id,
        valorMe: formatMoneyStringInDecimal(
          itemVitrine?.valorMe ?? itemVitrine.valorInicial
        ),
        valorMn: 0,
        impostos: [],
        valorVet: 0,
        idTipoOperacaoCarrinho: vitrine?.idTipoOperacao,
        idPraca: vitrine?.idPraca,
        idVitrine: vitrine?.id,
        idHotsite: user?.idHotsite ?? 0,
        idTipoCartao: itemVitrine?.idTipoCartao ?? 0,
        moeda: itemVitrine?.moeda,
        indisponivel: itemVitrine?.indisponivel,
        indisponivelText: itemVitrine?.indisponivelText,
        isCompra: false,
      };

      handlerCalculate(item);
    }
  }, [itemVitrine, vitrine, valorItemCarrinho]);

  useEffect(() => {
    setItemVitrine((prev) => ({
      ...prev,
      valorMe: configInitialvalue(itemVitrine),
    }));
  }, [itemVitrine?.valorInicial]);

  useEffect(() => {
    setLoadingButton(true);
    refetchImpostos();
    setItemVitrine((prev) => ({
      ...prev,
      valorMe: configInitialvalue(itemVitrine),
    }));
  }, [vitrine?.idPraca, itemVitrine?.id]);

  const handlerCalculate = async (i) => {
    try {
      if (!i.idVitrine) return;

      if (!valorItemCarrinho) {
        setLoadingButton(true);
        refetchImpostos();
        return;
      }

      let item = i;

      if (!isValidacoesPreCalculo(item?.valorMe)) {
        return;
      }

      item.indisponivel = valorItemCarrinho?.indisponivel;
      item.indisponivelText = valorItemCarrinho?.indisponivelText;

      item.impostos = valorItemCarrinho?.impostos;
      item.idTaxa = valorItemCarrinho?.idTaxa;
      item.valorTaxaAplicada = formatMoney(
        valorItemCarrinho?.valorTaxa,
        item.moeda.numeroCasaDecimais
      );

      const valorBaseMn = formatMoney(item.valorMe * item.valorTaxaAplicada, 2);

      // let valorMn = formatMoney(valorBaseMn, 2);

      let valorMnComImposto = parseFloat(valorBaseMn);
      let idTipoItemCarrinho = 0;

      if (vitrine.idTipoOperacao == TiposPedido.VendaEspecie) {
        item.isCompra = false;
        if (isEspecie) idTipoItemCarrinho = TiposItemCarrinho.VendaEspecie;
        else if (isRecarga)
          idTipoItemCarrinho = TiposItemCarrinho.RecargaCartao;
        else idTipoItemCarrinho = TiposItemCarrinho.VendaCartao;

        item?.impostos?.map((i) => {
          const valorImpostoBase = formatMoney(
            (valorMnComImposto * i.aliquota) / 100,
            2
          );
          i.valor = formatMoney(valorImpostoBase, 2);
          valorMnComImposto += parseFloat(valorImpostoBase);
        });
      }

      if (vitrine.idTipoOperacao == TiposPedido.CompraEspecie) {
        item.isCompra = true;
        if (isEspecie) idTipoItemCarrinho = TiposItemCarrinho.CompraEspecie;
        else idTipoItemCarrinho = TiposItemCarrinho.CompraSaldoCartao;

        item?.impostos?.map((i) => {
          const valorImpostoBase = formatMoney(
            (valorMnComImposto * i.aliquota) / 100,
            2
          );
          i.valor = formatMoney(valorImpostoBase, 2);
          valorMnComImposto -= parseFloat(valorImpostoBase);
        });
      }

      item.valorMn = formatMoney(valorBaseMn, 2);
      item.valorVet = formatMoney(
        valorMnComImposto / item.valorMe,
        item.moeda.numeroCasaDecimais
      );

      item.idCliente = user.id;
      item.valorTotalMN = valorMnComImposto;
      item.idTipoItemCarrinho = idTipoItemCarrinho;

      setItemCarrinho(item);

      if (item.indisponivel) throw new Error(item.indisponivelText);
    } catch (error) {
      showAlertError(error.message);
    }
  };

  const handlerAddCart = async (event, isContinueOperacao = true) => {
    event.preventDefault();

    setLoadingButton(true);

    try {
      if (lojaFechada && lojaFechada.isFechada) navigation("/LojaFechada");

      if (isContinueOperacao) {
        if (!termoAceito) {
          setIsErrorTermo(true);
          showAlertError("É necessário aceitar o termo de uso.");
        }

        if (!termoRespon) {
          setIsErrorTermoResp(true);
          showAlertError("É necessário aceitar o termo de responsabilidade.");
        }

        if (termoRespon && termoAceito) {
          if (
            itemCarrinho.idTipoItemCarrinho !==
            TiposItemCarrinho.RecargaCartao &&
            itemCarrinho.idTipoItemCarrinho !==
            TiposItemCarrinho.CompraSaldoCartao
          ) {
            setTimeout(() => {
              navigation("/carrinho/turismo/entrega");
            }, 2000);
          } else {
            let isRecarga =
              itemCarrinho.idTipoItemCarrinho ==
              TiposItemCarrinho.RecargaCartao;

            setTimeout(() => {
              navigation(
                `/carrinho/turismo/pagamento/${itemCarrinho.idTipoOperacaoCarrinho}/${isRecarga}`
              );
            }, 2000);
          }

          return;
        }
      }

      if (itemCarrinho.indisponivel)
        throw new Error(itemCarrinho.indisponivelText);

      const response = await addItem(
        itemCarrinho.idCliente,
        itemCarrinho.idPraca,
        idTipoOperacao,
        itemCarrinho
      );

      if (response && !!response.errorMessage) {
        throw new Error(response.errorMessage);
      }

      if (response) {
        refetchTurismo();
      }
    } catch (error) {
      showAlertError(error.message);
    } finally {
      setTimeout(() => {
        setLoadingButton(false);
      }, 2000);
    }
  };

  const handlerChangePraca = (id) => {
    setItemCarrinho({ ...itemCarrinho, idPraca: id });
    setVitrine({ ...vitrine, idPraca: id });
  };

  const handlerChangeItem = (event) => {
    setItemVitrine((prev) => ({
      ...vitrine.itensVitrine.find((i) => i.id === event.target.value),
    }));
    setValorItemCarrinho((prev) => null);
  };

  const handlerChangeValueMe = (event) => {
    let valor = event.target.value;

    setItemVitrine({
      ...itemVitrine,
      valorMe: valor,
    });
  };

  const handlerBlurItemChange = (event) => {
    let valor = event.target.value;

    if (!!!valor) valor = "00,00";

    if (!valor.includes(",")) valor += ",00";

    setItemVitrine({
      ...itemVitrine,
      valorMe: valor,
    });
  };

  return (
    <>
      <AlertCustom />
      {(loadingButton || isLoadingVitrine) && <LoadingVitrine />}
      {!loadingButton &&
        !isLoadingVitrine &&
        (vitrine ? (
          <>
            <VitrineBase
              vitrine={vitrine}
              onChangeItem={handlerChangeItem}
              onAddCart={handlerAddCart}
              itemSelected={itemVitrine}
              itemCarrinho={itemCarrinho}
              onChangePraca={handlerChangePraca}
              onChangeValorMe={handlerChangeValueMe}
              onChangeTermo={setTermoAceito}
              onChangeTermoRespon={setTermoRespon}
              isTermoAceite={termoAceito}
              isTermoResponsabilidade={termoRespon}
              onBlurValueChangeItem={handlerBlurItemChange}
              isErrorTermo={isErrorTermo}
              isErrorTermoResp={isErrorTermoResp}
              loadingButton={loadingButton}
              setLoadingButton={setLoadingButton}
              disabledButton={disabledButton}
              isCompraNovoCartao={isCompraNovoCartao}
              vitrinesList={vitrine}
            />
          </>
        ) : (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ height: "80vh", marginTop: "-1rem", background: "#fcfcfc" }}
          >
            <Grid item>
              <Card>
                <CardHeader subheader="❌ Comunicado importante" />
                <CardContent>
                  <Typography variant="body1">
                    A vitrine não esta disponivel para operações no momento.
                    <br />
                    Para mais detalhes, entre em contato conosco.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        ))}
    </>
  );
}
