import { makeStyles } from "@mui/styles";

const Style = makeStyles((theme) => ({
  containerPassageiro: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: 10,
    paddingRight: 10,
    cursor: "pointer",
  },
  Item:{
    display: "contents",
    width:'100%',
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      width:'100%',
    }
  },
  containerSelecao: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
  },
  boxSelecao: {
    width:'100%',
   
    justifyContent:"space-between",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  campoViajante: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  boxInformativo: {
    padding: 0,
    marginRight: 10,
  },
  boxButton: {
    justifyContent:"space-between",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  literalQuantidade: {
    fontSize: "20px",
    marginLeft: "10px",
    marginRight: "10px",
  },
  button: {
    cursor: "pointer",
    color:theme.palette.primary.main,
    margin:10,
    fontSize:25,
  },
  Campo:{
    margin:'0 !important',
    [theme.breakpoints.up("sm")]: {
     marginRight:'12px !important',
    }
  },
  boxCamposIdade: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  
}));

export default Style;
