import { useEffect, useState } from "react";
import { Grid, Skeleton } from "@mui/material";
import { CampoTexto, ButtonAcaoGrande } from "../";
import { AddCupomCarrinho, RemoveCupomCarrinho } from "../../api/Cupom";
import TiposPedido from "../../util/typesTipoPedido";
import useAlert from "../../hooks/useAlert";
import { useMutation } from "react-query";
import { useAuth } from "../../hooks/AuthContext";

const CupomDesconto = ({
  cupom,
  idTipoMercado,
  idMoeda,
  isLoading,
  setIsLoading,
  onReflesh,
  idTipoPedido,
}) => {
  const [codigoCupom, setCodigo] = useState("");
  const { Alert, showAlertError, showAlertSuccess } = useAlert();
  const { user } = useAuth();


  const mutationAddCupom = useMutation(AddCupomCarrinho, {
    onSuccess: (data) => {
      if (!data) {
        showAlertError(
          "Ops, Não conseguimos anexar o seu cupom, tente novamente ou entre em contato com nosso suporte tecnico - RKNNZ"
        );
        return;
      }

      if (data.errorMessage && data.errorMessage != "") {
        showAlertError(`${data.errorMessage} - RKW93`);
        return;
      }

      onReflesh();
    },

    onError: (error) => {
      showAlertError(`${error.message} - RPFH8`);
    },
    onSettled: () => {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);

    }
  });

  const mutationRemoveCupom = useMutation(RemoveCupomCarrinho, {
    onSuccess: (data) => {
      if (!data) {
        showAlertError(
          "Ops, Não conseguimos anexar o seu cupom, tente novamente ou entre em contato com nosso suporte tecnico - RA5B4"
        );
        return;
      }

      if (data.errorMessage && data.errorMessage != "") {
        showAlertError(`${data.errorMessage} - R0487`);
        return;
      }

      if (!data.status == 200) {
        showAlertError(
          `Ops, deu algo errado, informe ao suporte Tecnico: Erro ao selecionar a forma de pagamento - R99A0`
        );
        return;
      }

      onReflesh();
    },

    onError: (error) => {
      showAlertError(` ${error.message} - R09E8`);
    },
    onSettled: () => {
      setIsLoading(false);
    }
  });

  const handleAddCupom = (event) => {
    setIsLoading(true);

    mutationAddCupom.mutate({
      userID: user.id,
      codigoCupom,
      idTipoPedido,
    });
  };

  const handleRemoveCupom = () => {
    setIsLoading(true);
    mutationRemoveCupom.mutate({
      userID: user.id,
      idTipoPedido
    });
    setCodigo("");
  };

  const handleChangeCodigo = (event) => {
    setCodigo(event.target.value);
  };

  return (
    <>
      <Alert />
      <Grid
        container
        spacing={1}
        sx={{ display: "flex", alignItems: "center" }}
      >
        <Grid sx={{mt:1}} item xs alignSelf="center">
          {
            isLoading ?
              <Skeleton height={60} animation="wave" />
              :
              <CampoTexto
                sx={{ width: "100%" }}
                fullWidth={true}
                label="Cupom"
                size="small"
                value={codigoCupom}
                enable
                handlerChange={handleChangeCodigo}
              ></CampoTexto>
          }

        </Grid>
        <Grid item xs={3} alignSelf="center">
          {
            isLoading ?
              <Skeleton height={60} animation="wave" />
              :
              <ButtonAcaoGrande
                color="success"
                sx={{ ml: 0, p: "8px !important" }}
                texto="Adicionar"
                disabled={!!!codigoCupom}
                Handle={handleAddCupom}
              />
          }

        </Grid>
        {cupom && (
          <Grid item sx={{ m: 0 }} xs={3} alignSelf="center">
            {
              isLoading ?
                <Skeleton height={60} animation="wave" />
                :
                <ButtonAcaoGrande
                  color="error"
                  texto="Remover"
                  disabled={!!!codigoCupom}
                  Handle={handleRemoveCupom}
                />
            }

          </Grid>
        )}
      </Grid>
    </>
  );
};

export default CupomDesconto;
